.video-container
{
  border-radius: 5px;
  margin: 2px;
  border: 3px solid #EFEFEF; 
  text-align: center;
  padding: 10px 0;
  color: #EFEFEF;
  background-color: rgba(0,0,0,0.7);
  min-width: 28%;
}

.video-container.active
{
    border: none;
    min-width: 32%;
    border-width: 8px;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    overflow: hidden;
    padding: 2rem;
}

*, *::before, *::after {
    box-sizing: border-box;
  }

  @-webkit-keyframes rotate {
    100% {
      transform: rotate(1turn);
    }
  }
  
  @keyframes rotate {
    100% {
      transform: rotate(1turn);
    }
  }

.video-container.active::before {
    content: "";
    position: absolute;
    z-index: -2;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    background-repeat: no-repeat;
    background-size: 50% 50%, 50% 50%;
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    -webkit-animation: rotate 10s linear infinite;
            animation: rotate 10s linear infinite;
  }
  .video-container.active::after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 6px;
    top: 6px;
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    border-radius: 5px;
    background-color: rgba(0,0,0,0.3);
  }

  .video-container.active.green::before
  {      
    background-image: linear-gradient(#399953, #399953), linear-gradient(#1DD49E, #1DD49E), linear-gradient(#1DD49E, #1DD49E),linear-gradient(#399953, #399953);
  }

  .video-container.active.blue::before
  {      
    background-image: linear-gradient(#1b5e85, #1b5e85), linear-gradient(#3090C7, #3090C7), linear-gradient(#3090C7, #3090C7), linear-gradient(#1b5e85, #1b5e85);
  }

  .video-container.active.red::before
  {      
    background-image: linear-gradient(#9e1557,#9e1557),linear-gradient(#D9257B, #D9257B),linear-gradient(#D9257B, #D9257B),linear-gradient(#9e1557,#9e1557)
  }

.video-container.blue
{
  border-color: #3090C7;
}

.video-container.red
{
  border-color: #D9257B;
}

.video-container.green
{
  border-color: #1DD49E;
}

.btn
{
    margin-top: 10px;
    color: #FFFFFF;
}

.btn.green-bg:hover
{
    color: #EAEAEA;
}

.video-container.active .btn 
{
    background-color: #FFFFFF;
    color: #000000;
}

.progress-views
{
    font-size: 1.2em;
    font-weight: bolder;
    height: 20px;
    padding:2px;
    margin: 5px 1px 1px 1px;
}

@media only screen and (max-width : 768px) {

    .video-container
    {
      border-radius: 5px;
      margin: 5px;
      border: 8px solid #EFEFEF;
      text-align: center;
      padding: 10px 0;
      color: #EFEFEF;
      background-color: rgba(0,0,0,0.7);
      min-width: 80%;
    }
    
    .video-container.active
    {
        min-width: 100%;
    }
}