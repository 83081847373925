.message-container {
    display: flex;
    flex-direction: row;
    align-items:center;
}

.message
{
    margin:0;
    padding: 0.6rem;
    display: flex;
    flex-direction: row;
    align-items:center;
    font-size: 1.1rem;
    border-radius: 0.7rem;
    margin-bottom: 0.2rem;
    width: auto;
    max-width: 65%;
    position: relative;
}

.detailsContainer {
    font-size: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    align-self: flex-end;
    margin-right: 0;
    padding-right: 0;
    width: 5.5rem;
    margin-left: 1rem;
}

.message.mine
{
    margin-left: auto;
    align-items: flex-end;
}
