.videos-container
{
  display:flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.header
{
  text-align: center;
}

.header .subtitle
{
  font-size: 1.2em;
}

.rainbow
{
  display: inline-block;
  background: linear-gradient(90deg,#3090C7 0, #1DD49E 33%, #D9257B);
  background-clip:content-box;
  text-transform: uppercase;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /*
  -webkit-animation: textchange 1s linear infinite;
            animation: textchange 1s linear infinite;*/
  
}

@-webkit-keyframes textchange{
  0% {
    background: linear-gradient(90deg,#3090C7, #1DD49E, #D9257B);
    
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  }
  50% {
    background: linear-gradient(90deg,#1DD49E, #D9257B,#3090C7);
    
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  }
  100% {
    background: linear-gradient(90deg,#D9257B,#3090C7,#1DD49E);
    
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  }
}

@keyframes textchange {
  0% {
    background: linear-gradient(90deg,#3090C7, #1DD49E, #D9257B);
    
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  }
  50% {
    background: linear-gradient(90deg,#1DD49E, #D9257B,#3090C7);
    
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  }
  100% {
    background: linear-gradient(90deg,#D9257B,#3090C7,#1DD49E);
    
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  }
}