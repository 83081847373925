.messages-container
{
    background-color: #FFFFFF;
    color: #000000;
    height: auto;
    max-height: 500px;
    display: flex;
    flex-direction: column-reverse;
    padding: 1rem 2rem;
    overflow-y: auto;
}